import { FunctionComponent, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { LinkTo } from '@common/components/default/LinkTo/LinkTo';
import {
	getCategoryUri,
	getSportUri,
	getTeamUri,
	getTournamentUri,
} from '@legacyApp/client/modules/app/sportsService';
import { boxNoHeight } from '@legacyApp/client/modules/style/defaults';
import { transSports } from '@legacyApp/client/modules/translation/translate';
import { SportContext } from '../../context/SportContext';
import { TournamentContext } from '../../context/TournamentContext';
import { MatchContext } from '../../context/MatchContext';
import { createNameFromSlug } from '../../methods/createNameFromSlug';
import { TeamContext } from '../../context/TeamContext';
import { SportContextType } from '../../types/Sport/SportContextType';
import { SportType } from '../../types/Sport/SportType';
import { TournamentContextType } from '../../types/Tournament/TournamentContextType';
import { TournamentMatchType } from '../../types/Tournament/TournamentMatchType';
import { CategoryContext } from '../../context/CategoryContext';
import { SportName } from '../SportName/SportName';
import BreadcrumbsLoader from '../../../../common/components/contentLoaders/BreadcrumbsLoader';

const parseData = (
	data: SportContextType | TournamentContextType,
	matchData?: SportType | TournamentMatchType,
) => ({
	name: matchData?.name || data?.name || createNameFromSlug(data?.slug) || '',
	slug: matchData?.slug || data?.slug || '',
	id: matchData?.id || data?.id || '',
});

const Breadcrumbs: FunctionComponent = () => {
	const sport = useContext(SportContext);
	const category = useContext(CategoryContext);
	const tournament = useContext(TournamentContext);
	const { match } = useContext(MatchContext);
	const team = useContext(TeamContext);

	const [sportData, setSportData] = useState(parseData(sport, match?.sport));
	useEffect(() => {
		setSportData(parseData(sport, match?.sport));
	}, [sport?.id, match?.sport?.id]);

	const [teamData, setTeamData] = useState(parseData(team));
	useEffect(() => {
		setTeamData(parseData(team));
	}, [team?.id, team?.name]);

	const [tournamentData, setTournamentData] = useState(
		parseData(tournament, match?.tournament),
	);
	useEffect(() => {
		setTournamentData(parseData(tournament, match?.tournament));
	}, [tournament?.id, match?.tournament?.id, tournament?.name]);

	const [categoryData, setCategoryData] = useState(
		parseData(category, match?.category),
	);
	useEffect(() => {
		setCategoryData(parseData(category, match?.category));
	}, [category?.id, match?.category?.id, category?.name, match?.category]);

	// console.log('SlotsBreadcrumbs', {
	// 	tournamentData,
	// 	categoryData,
	// 	sportData,
	// 	sport,
	// 	tournament,
	// 	match,
	// 	teamData,
	// 	team,
	// });

	const isOnlySport = !(
		tournamentData?.id ||
		categoryData?.id ||
		match?.tournament?.id ||
		teamData?.id
	);
	return (
		<StyledBreadcrumbs className="text-capitalize">
			{sportData?.name ? (
				<LinkTo href={`${getSportUri(sportData.id, sportData.slug)}`}>
					<a>
						<SportName
							seoLabel={isOnlySport}
							name={sportData.name}
							iconId={sportData.slug}
						/>
					</a>
				</LinkTo>
			) : (
				<BreadcrumbsLoader id={'1'} />
			)}
			{!isOnlySport ? (
				<>
					<StyledBreadcrumbsSpacer />
					{team?.id ? (
						<LinkTo href={getTeamUri(sportData, teamData)}>
							<a>{teamData.name}</a>
						</LinkTo>
					) : (
						<>
							<LinkTo href={getCategoryUri(sportData, categoryData)}>
								<a>{transSports({ label: categoryData.name })}</a>
							</LinkTo>
							{tournamentData?.id ? (
								<>
									<StyledBreadcrumbsSpacer />
									<LinkTo
										forceATag
										href={getTournamentUri(
											sportData,
											categoryData,
											tournamentData,
										)}
									>
										<a>{tournamentData.name}</a>
									</LinkTo>
								</>
							) : null}
						</>
					)}
				</>
			) : null}
		</StyledBreadcrumbs>
	);
};

export const StyledBreadcrumbsSpacer = styled.i.attrs({
	className: 'icon__arrow-down',
})`
	display: inline-flex;
	margin: 0 1rem 0 0.75rem;

	&:after {
		border-color: var(--color-misc-primary-gradient-to);
		transform: rotate(315deg);
	}
`;

export const StyledBreadcrumbs = styled.div.attrs<{
	className?: string;
}>({ className: 'text-style-md-regular' })`
	${boxNoHeight};
	align-items: center;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	display: flex;
	justify-content: flex-start;
	margin-bottom: 1.5rem;
	padding: 1rem 0;

	> a,
	> h1 {
		align-items: center;
		display: flex;
	}

	svg {
		margin-right: 0.25rem;
		min-width: 15px;
	}
`;

export { Breadcrumbs };
