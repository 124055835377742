import { Input } from '@ui/input';
import { useCallback, useRef, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import styled, { css } from 'styled-components';
import { Search } from '@icons/bottom-menu/search';
import {
	InputBoxStyled,
	InputIconWrapperStyled,
	InputStyled,
	InputWrapperStyled,
} from '@ui/input/Input.styled';
import {
	Dropdown,
	DropdownItemProps,
	StyledDropdownButtonWrapper,
} from '@ui/dropdown';
import { useFetchSearchTerm } from '@modules/slots/hooks/useFetchSearchTerm';
import {
	StyledNoResults,
	useFetchSearchDefault,
} from '@modules/slots/components/search/SlotsSearchModal';
import { Loader } from '@legacyApp/client/components/loader/loader';
import {
	SlotsSearchResult,
	StyledResultLayout,
} from '@modules/slots/components/search/SlotsSearchResult';
import { trans } from '@legacyApp/client/modules/translation/translate';
import { Size } from '@uiTypes/Size';
import { Slot } from '@modules/slots/store/slots.types';
import { simpleTrans } from '@legacyApp/client/modules/translation/translationService';

const mapSlotsToDropdown = (list: Array<Slot>): Array<DropdownItemProps> =>
	list.map((slot) => {
		const id = `${slot.game_id}_${slot.operator_id}`;
		return {
			id,
			content: <SlotsSearchResult key={id} slot={slot} />,
		};
	});

const NO_RESULTS = [
	{
		id: 'no-result',
		content: (
			<StyledNoResults>{trans({ label: 'No results' })}</StyledNoResults>
		),
	},
];

const LOADING = [
	{
		id: 'loading',
		content: (
			<StyledNoResults>
				<Loader active={true} />
			</StyledNoResults>
		),
	},
];

export const GamesSearchBar = () => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const focusRef = useRef(null);

	const focus = useCallback(() => {
		if (typeof focusRef.current?.querySelector !== 'function') {
			return;
		}
		const input = focusRef.current.querySelector('input');
		if (typeof input?.focus === 'function') {
			input.focus();
		}
	}, []);

	const toggleOpen = useCallback(
		(event, isOpen_) => {
			if (typeof event?.preventDefault === 'function') {
				event.preventDefault();
			}

			if (isOpen_ === undefined) {
				isOpen_ = !isOpen;
			}

			if (isOpen_ !== isOpen) {
				setIsOpen(isOpen_);
				if (isOpen_) {
					focus();
				}
			}
		},
		[focus, isOpen],
	);

	const handleClickOutside_ = useCallback(() => {
		setTimeout(() => {
			if (!isOpen) {
				return;
			}
			toggleOpen(null, false);
		}, 150);
	}, [isOpen, toggleOpen]);

	const outSideRef = useOnclickOutside(handleClickOutside_);

	const { results, loading, setTerm, term } = useFetchSearchTerm();
	const { list } = useFetchSearchDefault();

	const getList = useCallback((): Array<DropdownItemProps> => {
		const termResults = mapSlotsToDropdown(results);
		const defaultResults = mapSlotsToDropdown(list);

		if (term?.length) {
			if (!loading) {
				if (results?.length) {
					return termResults;
				} else {
					return NO_RESULTS;
				}
			} else {
				return LOADING;
			}
		} else {
			return defaultResults;
		}
	}, [list, loading, results, term?.length]);

	const list_ = getList();

	return (
		<GameSearchBarStyled
			$isOpen={isOpen}
			ref={outSideRef}
			onClick={(event) => toggleOpen(event, true)}
		>
			<Dropdown
				forceVisible={isOpen}
				defaultActiveId={null}
				list={list_}
				onSelect={null}
				size={Size.LARGE}
			>
				<div ref={outSideRef}>
					<Input
						value={term}
						placeholder={simpleTrans('Search for game')}
						onChange={(event) => setTerm(event.target.value)}
						icon={<Search />}
					/>
				</div>
			</Dropdown>
		</GameSearchBarStyled>
	);
};

export interface GameSearchBetStyledProps {
	$isOpen?: boolean;
}

export const GameSearchBarStyled = styled.div<GameSearchBetStyledProps>`
	width: ${(props) => (props.$isOpen ? '350px' : '42px')};

	${StyledDropdownButtonWrapper} {
		> div {
			width: 100%;
			float: right;
		}
	}

	> div {
		float: left;
		width: 100%;
	}

	${InputIconWrapperStyled} {
		transition: right 300ms;
		left: unset;

		svg {
			height: 16px;
			width: 16px;
		}

		${(props) =>
			props.$isOpen
				? css`
						right: calc(100% - 28px);
				  `
				: css`
						right: 14px;
				  `}
	}

	${InputBoxStyled} {
		align-items: flex-end;
	}

	> div:not(${StyledDropdownButtonWrapper}) {
  	max-width: 420px;
	}

	${InputWrapperStyled} {

		${(props) =>
			props.$isOpen
				? css`
						width: 100%;
				  `
				: css`
						background-color: var(--color-dark-500);
						border-radius: 40px;
						box-shadow: 0 1px 5px var(--color-dark-900);
				  `}
	}

	${InputStyled} {
		border-radius: 40px;
		transition:  width 300ms;
		width: 100%;
		box-shadow: 0 1px 5px var(--color-dark-900);
		float: right;

		${(props) =>
			props.$isOpen
				? css``
				: css`
						height: 42px;
						opacity: 0;
						padding: 0;
						width: 42px;
				  `}
	}

	${StyledResultLayout} {
		padding: 0;
	}

	@media (max-width: ${(props) => props.theme.media_v2.max_mobile_medium}) {
		${InputStyled} {
			${(props) =>
				props.$isOpen
					? null
					: css`
							height: 36px;
							width: 36px;
					  `}
		}

		${InputIconWrapperStyled} {
			svg {
				height: 12px;
				width: 12px;
			}

			${(props) =>
				props.$isOpen
					? css`
							right: calc(100% - 28px);
					  `
					: css`
							right: 12px;
					  `}
		}
	}

	${(props) =>
		props.$isOpen
			? css`
					@media (max-width: ${(props) => props.theme.media.maxWidthTablet}) {
						width: calc(100% - 32px);
					}

					@media (max-width: ${(props) =>
							props.theme.media_v2.max_mobile_medium}) {
						width: calc(100% - 24px);
					}
			  `
			: css`
					@media (max-width: ${(props) =>
							props.theme.media_v2.max_mobile_medium}) {
						width: 36px;
					}
			  `}
	}
`;
