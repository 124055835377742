import React, { FC } from 'react';
import styled from 'styled-components';

import { trans, transSlots } from '@legacyApp/client/modules/translation/translate';
import { generateSlotCategoryUrl, generateSlotProviderUrl, generateSlotsHomeUrl } from '@modules/slots/services/Slots.service';
import { ProviderDTO, SlotCategoryDTO } from '@modules/slots/store/slots.types';

import { LinkTo } from '@common/components/default/LinkTo/LinkTo';
import { Lobby } from '@icons/lobby';
import { StyledBreadcrumbs, StyledBreadcrumbsSpacer } from '@modules/sports/components/Breadcrumbs/Breadcrumbs';

interface BreadcrumbsProps {
	title?: string | JSX.Element;
	category?: SlotCategoryDTO;
	provider?: ProviderDTO;
}

const StyledSlotBreadcrumbs = styled(StyledBreadcrumbs)`
	border-bottom: none !important;
	margin-bottom: 0 !important;
	overflow-x: auto;
	white-space: nowrap;
`;

const SlotsBreadcrumbs: FC<BreadcrumbsProps> = ({
	category,
	title,
	provider,
}) => {
	const categoryName = category?.name;

	const categorySlug = category?.slug;

	const providerName = provider?.name;

	const providerSlug = provider?.slug;

	return (
		<StyledSlotBreadcrumbs className="text-capitalize">
			<LinkTo href={generateSlotsHomeUrl()}>
				<a>
					<Lobby fill={'#1566df'} /> {trans({ label: 'Lobby' })}
				</a>
			</LinkTo>

			{category?.id && (
				<>
					<StyledBreadcrumbsSpacer />

					<LinkTo href={generateSlotCategoryUrl(categorySlug)}>
						<a>{transSlots({ label: categoryName })}</a>
					</LinkTo>
				</>
			)}

			{provider && (
				<>
					<StyledBreadcrumbsSpacer />

					<LinkTo href={generateSlotProviderUrl(providerSlug)}>
						<a>{providerName}</a>
					</LinkTo>
				</>
			)}

			{title && (
				<>
					<StyledBreadcrumbsSpacer />

					<span className="text-style-md-regular">{title}</span>
				</>
			)}
		</StyledSlotBreadcrumbs>
	);
};

export { SlotsBreadcrumbs };
