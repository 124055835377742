import styled from 'styled-components';
import { TabNavigationStyled } from '@ui/tab-navigation/TabNavigation.styled';
import { GameSearchBarStyled } from './GamesSearchBar';

export const SectionsTabNavStyled = styled.div`
	float: left;
	position: relative;
	width: 100%;

	${GameSearchBarStyled} {
		position: absolute;
		right: 16px;
		top: 15px;
		z-index: 3;

		&:before {
			background-color: var(--color-dark-700);
			content: '';
			border-top-left-radius: 46px;
			border-bottom-left-radius: 46px;
			height: 105%;
			left: 0;
			position: absolute;
			top: -2.5%;
			width: calc(100% + 6px);
			z-index: 0;
			transition: 300ms;
		}
	}

	${TabNavigationStyled} {
		border-bottom-right-radius: 0;
		border-top-right-radius: 0;
		padding-right: 30px;
		width: calc(100% - 15px);
	}

	@media (max-width: ${(props) => props.theme.media_v2.max_mobile_medium}) {
		${GameSearchBarStyled} {
			right: 12px;
			top: 12px;
		}

		div.margin-top-48 {
			margin-top: 24px;
		}
	}
`;
