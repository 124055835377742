import { useContext, useEffect } from 'react';
import { useGetDataArray } from '@legacyApp/hooks/fetch/useGetDataArray';
import { getMainPageCategories } from '../methods/getMainPageCategories';
import { SlotsFirstSectionContext } from '../context/SlotsFirstSectionContext';
import { SlotsBottomSectionsContext } from '../context/SlotsBottomSectionsContext';
import { SlotCategoryDTO } from '../store/slots.types';
import { useFetchCategories } from './useFetchCategories';

export const useMainSections = () => {
	const { firstSection, setFirstSection } = useContext(
		SlotsFirstSectionContext,
	);
	const { bottomSections, setBottomSections } = useContext(
		SlotsBottomSectionsContext,
	);

	const { callback, options } = useFetchCategories();
	const { data: categories } = useGetDataArray<SlotCategoryDTO>(
		'useMainPageSections-categories',
		callback,
		options,
	);

	useEffect(() => {
		if (categories?.length && (!bottomSections?.length || !firstSection)) {
			getMainPageCategories(undefined, categories, { list: [{}] }).then(
				({ firstSection: firstSection_, bottomSections: bottomSections_ }) => {
					if (firstSection_?.games?.list?.length) {
						setFirstSection(firstSection_);
					}
					if (bottomSections_?.length) {
						setBottomSections(bottomSections_);
					}
				},
			);
		}
	}, [categories?.length]);

	return {
		firstSection,
		bottomSections,
	};
};
