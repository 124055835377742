import React, { FC } from 'react';
import styled from 'styled-components';
import { trans } from '@legacyApp/client/modules/translation/translate';
import {
	AVAILABLE_OFFER,
	MainPageOfferButton,
} from '../../layout/MainPageOfferButton/MainPageOfferButton';
import { StyledGamesList } from '../../games/GamesList/GamesList.styled';
import { ScrollCardsWithTitle } from '../../layout/ScrollCards/ScrollCardsWithTitle';

export const Inhouse: FC<{
	noName?: boolean;
}> = ({ noName }) => {
	return (
		<>
			<ScrollCardsWithTitle
				name={!noName && trans({ label: 'In house' })}
				noList
			>
				<StyledGamesGrid>
					<StyledOfferList $length={AVAILABLE_OFFER?.length}>
						{AVAILABLE_OFFER.map((offer) => {
							return <MainPageOfferButton key={offer} offerName={offer} />;
						})}
					</StyledOfferList>
				</StyledGamesGrid>
			</ScrollCardsWithTitle>
		</>
	);
};

const StyledGamesGrid = styled.div`
	display: flex;
	justify-content: center;
`;

const StyledOfferList = styled(StyledGamesList)<{
	$length: number;
}>`
	grid-template-columns: repeat(${(props) => props.$length}, auto);
	padding: 0;

	@media (max-width: ${(props) => props.theme.media.maxWidthTablet}) {
		grid-template-columns: repeat(2, auto);
	}
`;
