import {
	TabNavigation as UITabNavigation,
	TabNavigationProps,
} from '@ui/tab-navigation';
import { FC, PropsWithChildren } from 'react';
import { useTabNavigation, UseTabNavigationProps } from './useTabNavigation';
import { TabNavigationContext } from './TabNavigation.context';

interface TabNavigationProps_ {
	tabs: TabNavigationProps['tabs'];
	onClick?: TabNavigationProps['onClick'];
	activeTabId?: TabNavigationProps['activeTabId'];
	defaultTabId?: TabNavigationProps['activeTabId'];
	queryKey?: UseTabNavigationProps['queryKey'];
	handleUrlQuery?: UseTabNavigationProps['handleUrlQuery'];
	onChange?: UseTabNavigationProps['onChange'];
}

export const TabNavigation: FC<PropsWithChildren<TabNavigationProps_>> = ({
	tabs,
	onClick,
	activeTabId,
	defaultTabId,
	children,
	queryKey,
	handleUrlQuery,
	onChange,
}) => {
	const {
		activeTabId: activeTabId_,
		content: Content,
		onTabClick,
		changeTabById,
	} = useTabNavigation({
		tabs,
		activeTabId,
		defaultTabId,
		queryKey,
		handleUrlQuery,
		onChange,
	});

	return (
		<TabNavigationContext.Provider
			value={{
				activeId: activeTabId_,
				changeTabById,
			}}
		>
			<UITabNavigation
				tabs={tabs}
				activeTabId={activeTabId_}
				onClick={onClick || onTabClick}
			>
				{children}
			</UITabNavigation>
			<div className="margin-top-48">{Content ? <Content /> : null}</div>
		</TabNavigationContext.Provider>
	);
};
