import { FC } from 'react';
import { IconProps } from '@icons/all-bets';
import { Svg } from '@legacyApp/client/components/svg/Svg';

export const Search: FC<IconProps> = ({ fill }) => {
	const _fill = fill || 'white';
	return (
		<Svg
			content={
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M5.25 10.5C6.49 10.5 7.62959 10.0701 8.52787 9.35124L10.6709 11.4942C10.8987 11.7221 11.268 11.7221 11.4958 11.4942C11.7236 11.2664 11.7236 10.8971 11.4958 10.6693L9.35265 8.5261C10.0707 7.6281 10.5 6.48919 10.5 5.25C10.5 2.3505 8.1495 0 5.25 0C2.3505 0 0 2.3505 0 5.25C0 8.1495 2.3505 10.5 5.25 10.5ZM5.25 1.16667C2.99484 1.16667 1.16667 2.99484 1.16667 5.25C1.16667 7.50516 2.99484 9.33333 5.25 9.33333C6.34799 9.33333 7.34475 8.89997 8.07859 8.19495C8.09509 8.17293 8.11335 8.15182 8.13338 8.13179C8.15333 8.11184 8.17438 8.09363 8.19631 8.07717C8.90053 7.34347 9.33333 6.34728 9.33333 5.25C9.33333 2.99484 7.50516 1.16667 5.25 1.16667Z"
					fill={_fill}
				/>
			}
			width={12}
			height={12}
		/>
	);
};
