import { IconProps } from '@icons/all-bets';
import { FC } from 'react';
import { Svg } from '@legacyApp/client/components/svg/Svg';
import { useId } from '../hooks/useId';

export const Lobby: FC<IconProps> = ({ fill, fillStop }) => {
	const { get } = useId({ id: 'all_bets' });
	const fill_ = fill || 'white';
	const fillStop_ = fillStop || fill_;
	return Svg({
		content: (
			<>
				<path
					d="M7.50024 7.1875C7.50024 6.84232 7.22042 6.5625 6.87524 6.5625C6.53007 6.5625 6.25024 6.84232 6.25024 7.1875V7.8125H5.62524C5.28007 7.8125 5.00024 8.09232 5.00024 8.4375C5.00024 8.78268 5.28007 9.0625 5.62524 9.0625H6.25024V9.6875C6.25024 10.0327 6.53007 10.3125 6.87524 10.3125C7.22042 10.3125 7.50024 10.0327 7.50024 9.6875V9.0625H8.12524C8.47042 9.0625 8.75024 8.78268 8.75024 8.4375C8.75024 8.09232 8.47042 7.8125 8.12524 7.8125H7.50024V7.1875Z"
					fill={get().url}
				/>
				<path
					d="M11.2502 8.4375C11.2502 8.09232 11.5301 7.8125 11.8752 7.8125H14.3752C14.7204 7.8125 15.0002 8.09232 15.0002 8.4375C15.0002 8.78268 14.7204 9.0625 14.3752 9.0625H11.8752C11.5301 9.0625 11.2502 8.78268 11.2502 8.4375Z"
					fill={get().url}
				/>
				<path
					d="M17.3185 7.09753C17.6574 7.03169 17.9854 7.253 18.0513 7.59184L19.3343 14.1945C19.44 14.7844 19.3545 15.3927 19.0903 15.9306C18.8262 16.4686 18.397 16.9081 17.8655 17.185C17.3341 17.462 16.728 17.5619 16.1357 17.4703C15.5435 17.3786 14.9959 17.1002 14.573 16.6755L15.0159 16.2345L14.5513 16.6526L11.1763 12.9026C10.9454 12.646 10.9662 12.2508 11.2228 12.0199C11.4793 11.789 11.8745 11.8098 12.1054 12.0664L15.469 15.8037C15.7025 16.0338 16.0026 16.1848 16.3269 16.235C16.6559 16.2859 16.9926 16.2304 17.2879 16.0765C17.5832 15.9227 17.8216 15.6785 17.9683 15.3796C18.1145 15.082 18.1622 14.7456 18.1045 14.4192L16.8242 7.83027C16.7584 7.49143 16.9797 7.16337 17.3185 7.09753Z"
					fill={get().url}
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M13.4377 3.72656C14.6809 3.72656 15.8731 4.22042 16.7522 5.0995C17.6313 5.97858 18.1252 7.17086 18.1252 8.41406C18.1252 9.65726 17.6313 10.8495 16.7522 11.7286C15.8735 12.6074 14.6818 13.1012 13.4392 13.1016C13.4387 13.1016 13.4382 13.1016 13.4377 13.1016L8.63827 13.1237L5.44823 16.6534L4.98453 16.2344L5.42738 16.6754C5.00447 17.1001 4.45696 17.3785 3.86469 17.4702C3.27242 17.5618 2.66635 17.4619 2.13487 17.1849C1.60338 16.908 1.17426 16.4685 0.91007 15.9305C0.645884 15.3926 0.560449 14.7843 0.666221 14.1944L0.667894 14.185L1.94823 7.61978C2.14156 6.53735 2.70866 5.55703 3.55075 4.8498C4.39412 4.1415 5.45965 3.75221 6.56099 3.75M13.4377 3.72656V4.35156ZM13.4377 4.97656L6.56479 5C5.75653 5.00153 4.97359 5.2872 4.35465 5.80701C3.73571 6.32682 3.31923 7.0477 3.17805 7.84354L2.56267 7.73438L3.17611 7.854L1.89584 14.4193C1.83825 14.7457 1.88594 15.082 2.03207 15.3795C2.17884 15.6784 2.41724 15.9225 2.71251 16.0764C3.00778 16.2303 3.34449 16.2858 3.67353 16.2349C3.998 16.1847 4.29828 16.0335 4.53186 15.8031L7.89585 12.0809C8.01367 11.9506 8.18093 11.8758 8.35665 11.875L13.4348 11.8516L13.4377 11.8516C14.3493 11.8516 15.2237 11.4894 15.8683 10.8447C16.513 10.2001 16.8752 9.32574 16.8752 8.41406C16.8752 7.50238 16.513 6.62804 15.8683 5.98338C15.2239 5.33897 14.35 4.97683 13.4387 4.97656C13.4383 4.97656 13.438 4.97656 13.4377 4.97656Z"
					fill={get(1).url}
				/>
				<defs>
					<linearGradient
						id={get().id}
						x1="5.00024"
						y1="12.8503"
						x2="19.3784"
						y2="12.8503"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor={fill_} />
						<stop offset="1" stopColor={fillStop_} />
					</linearGradient>
					<linearGradient
						id={get(1).id}
						x1="0.62207"
						y1="11.6441"
						x2="18.1252"
						y2="11.6441"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor={fill_} />
						<stop offset="1" stopColor={fillStop_} />
					</linearGradient>
				</defs>
			</>
		),
		width: 20,
		height: 20,
	});
};
