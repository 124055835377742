import React, {
	FC,
	PropsWithChildren,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useWindowEventListener } from 'rooks';

import { isFunction } from '@common/methods/isFunction';
import { GAMES_ON_PAGE } from '@modules/slots/constants/SlotMenu.constants';

import { StyledSlotsGridLayout } from '@modules/slots/components/TileSlotsGrid';

export interface SlotsGridLayoutProps {
	setGamesCount?: (count: number) => void;
}

const getColumnsCount = (gridElement: HTMLElement) => {
	if (!gridElement) {
		return 0;
	}

	const gridComputedStyle = window.getComputedStyle(gridElement);
	// get number of grid rows
	// const gridRowCount = gridComputedStyle.getPropertyValue('grid-template-rows').split(' ').length;
	// get number of grid columns
	return gridComputedStyle.getPropertyValue('grid-template-columns').split(' ')
		.length;
};

const getGamesCount = (gridElement: HTMLElement) => {
	if (!gridElement) {
		return GAMES_ON_PAGE;
	}

	const columnsCount = getColumnsCount(gridElement);

	return columnsCount * 6;
};

export const SlotsGridLayout: FC<PropsWithChildren<SlotsGridLayoutProps>> = ({
	children,
	setGamesCount,
}) => {
	const gridRef_ = useRef(null);

	const [gamesCount_, setGamesCount_] = useState<number>(GAMES_ON_PAGE);

	const updateGamesCount = useCallback(() => {
		const gamesCount = getGamesCount(gridRef_.current);

		if (gamesCount) {
			setGamesCount_(gamesCount);
		}
	}, [setGamesCount_]);

	useWindowEventListener('resize', updateGamesCount);

	useEffect(() => {
		updateGamesCount();
	}, [updateGamesCount]);

	useEffect(() => {
		if (!isFunction(setGamesCount)) {
			return;
		}

		setGamesCount(gamesCount_);
	}, [gamesCount_, setGamesCount]);

	return (
		<StyledSlotsGridLayout ref={gridRef_}>{children}</StyledSlotsGridLayout>
	);
};
