import styled from 'styled-components';

export const StyledProgressBar = styled.div<{
	progress: number;
}>`
	background-color: var(--color-dark-500);
	border-radius: 4px;
	display: block;
	height: 4px;
	width: 25vw;
	&:before {
		background-color: var(--color-blue-dark);
		border-radius: 4px;
		content: ' ';
		display: block;
		height: 100%;
		width: ${(props) => props.progress}%;
	}
`;
