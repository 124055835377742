import React, { FC } from 'react';
import styled from 'styled-components';

import { useUserIsAdmin } from '@common/selectors/user.selectors';
import { useGetDataArray } from '@legacyApp/hooks/fetch/useGetDataArray';
import { useFetchProviders } from '@modules/slots/hooks/useFetchProviders';
import { ProviderDTO } from '@modules/slots/store/slots.types';

import { LoaderListWrapper } from '@common/components/contentLoaders/LoaderListWrapper';
import { RectangleLoader } from '@common/components/contentLoaders/RectangleLoader';
import { ScrollCards } from '@common/components/layout/ScrollCards/ScrollCards';
import { StyledHorizontalListWrapper } from '@modules/slots/components/HrizontalListWrapper.styled';
import { StyledLoaderListItemWrapper } from '@modules/slots/components/LoaderListItemWrapper.styled';
import { ProviderTile } from '@modules/slots/components/ProviderTile';

const DEFAULT_COUNT = 6;

export const StyledProvidersSection = styled.div`
	margin-bottom: 16px;
	.menu-wrapper {
		width: 100%;
	}

	.menu-wrapper--inner {
		display: flex;
	}

	.scroll-menu-arrow {
		top: -44px !important;
	}
`;

const StyledProviderTileWrapper = styled.div`
	margin-right: 10px;
`;

export const ProvidersSection: FC = () => {
	const { callback, options } = useFetchProviders();

	const userIsAdmin = useUserIsAdmin();

	const { data: providers_, loaded } = useGetDataArray<ProviderDTO>(
		`providers-${userIsAdmin}`,
		callback,
		options,
	);

	return (
		<StyledProvidersSection>
			<LoaderListWrapper
				list={providers_}
				loaded={loaded}
				preloadElement={(el, index) => (
					<StyledLoaderListItemWrapper key={index}>
						<RectangleLoader id={index} />
					</StyledLoaderListItemWrapper>
				)}
				preloadLength={DEFAULT_COUNT}
				loaderWrapper={StyledHorizontalListWrapper}
				listElement={
					<StyledProvidersSection>
						<ScrollCards
							list={providers_.map((provider: ProviderDTO) => {
								return {
									name: provider.name,
									component: (
										<StyledProviderTileWrapper key={provider.id}>
											<ProviderTile
												slug={provider.slug}
												isNew={provider.new}
												name={provider.name}
											/>
										</StyledProviderTileWrapper>
									),
								};
							})}
						/>
					</StyledProvidersSection>
				}
			/>
		</StyledProvidersSection>
	);
};
