import styled from 'styled-components';

export const StyledLoaderListItemWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 10px 10px 0;
	> svg {
		width: 220px;

		@media (max-width: ${(props) => props.theme.media.maxWidthTablet}) {
			width: 200px;
		}

		@media (max-width: ${(props) => props.theme.media.maxWidthSmallTablet}) {
			width: 175px;
		}
	}
	&:last-child {
		margin: 0 0 10px 0;
	}
`;
