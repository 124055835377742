import { FC } from 'react';
import styled from 'styled-components';
import {
	useAppDeviceType,
	useAppLanguage,
} from '@common/selectors/app.selectors';
import { getTimestamp } from '@legacyApp/client/modules/app/timeService';
import { Image } from '@common/components/default/Image/Image';
import { SlotCategoryDTO } from '../store/slots.types';

export const useBannerSize = () => {
	const deviceType = useAppDeviceType();
	if (deviceType === 'desktopBig') {
		return '1920X400';
	}
	if (deviceType === 'desktop') {
		return '1920X600';
	}
	if (deviceType === 'tablet') {
		return '1095X463';
	}
	if (deviceType === 'mobileSmall') {
		return '1080X1080';
	}
	return '1200X630';
};

const useBannerType = () => {
	const isAfter = getTimestamp('2023-05-31 00:00:00') < getTimestamp();
	return isAfter ? 'after' : 'before';
};

const useBannerLanguage = () => {
	const language = useAppLanguage();
	if (['de', 'en', 'jp', 'ru', 'tr'].indexOf(language) === -1) {
		return 'en';
	}
	return language;
};

export const SlotCategoryBanner: FC<{ category: SlotCategoryDTO }> = ({
	category,
}) => {
	const language = useBannerLanguage();
	const size = useBannerSize();
	const type = useBannerType();

	if (category.slug !== 'drops-and-wins') {
		return null;
	}

	return (
		<StyledSlotCategoryBanner>
			<Image
				src={`/img/slots/drops-and-wins/banners/${type}/${language}/${size}.png`}
				alt={'Category Banner'}
			/>
		</StyledSlotCategoryBanner>
	);
};

export const StyledSlotCategoryBanner = styled.div`
	margin-bottom: 24px;
	margin-top: 24px;
	overflow: hidden;

	img {
		border-radius: 12px;
		width: 100%;
	}
`;
