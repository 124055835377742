import { FC } from 'react';
import { getTimestamp } from '@legacyApp/client/modules/app/timeService';
import { Image } from '@common/components/default/Image/Image';
import {
	StyledSlotCategoryBanner,
	useBannerSize,
} from '@modules/slots/components/SlotCategoryBanner';
import { Button } from '@ui/button';
import routingService from '@legacyApp/client/modules/app/routingService';
import { useDispatchCallback } from '@legacyApp/hooks/store/useAppDispatch';
import { setConfirm } from '@legacyApp/client/store/confirm/confirm.actions';
import { trans } from '@legacyApp/client/modules/translation/translate';
import { Size } from '@uiTypes/Size';
import { ButtonStyleType } from '@uiTypes/ButtonStyleType';

const useAvailable = () => {
	return (
		getTimestamp('2024-10-31T00:00:00.000Z') < getTimestamp() &&
		getTimestamp('2024-11-03T00:00:00.000Z') > getTimestamp()
	);
};

export const SlotHalloweenBanner: FC<{
	slug: string;
	enableDetails?: boolean;
	className?: string;
}> = ({ slug, enableDetails, className }) => {
	const size = useBannerSize();
	const isAvailable = useAvailable();

	const dispatchSetConfirm = useDispatchCallback(setConfirm);

	if (slug !== 'halloween' || !isAvailable) {
		return null;
	}

	const props = enableDetails
		? {}
		: {
				as: 'a',
				onClick: () =>
					routingService.dynamicRedirect('/slots/group/halloween', true),
		  };

	return (
		// @ts-expect-error incorrect props
		<StyledSlotCategoryBanner {...props} className={className}>
			<div className="flex justify-center items-center flex-column gap-4">
				<Image
					src={`/img/slots/halloween/${
						enableDetails ? '' : 'button/'
					}${size}.png`}
					alt={'Halloween Banner'}
				/>
				{enableDetails && (
					<Button
						size={Size.SMALL}
						styleType={ButtonStyleType.PRIMARY}
						onClick={() => {
							dispatchSetConfirm({
								question: [
									'Event starts on 31st October 2024 00:00 UTC and ends on 3rd November 2024 00:00 UTC.',
									'',
									'Top 3 players with the highest win multiplier will share the prize pool',
									'1. $400',
									'2. $200',
									'3. $100',
									'',
									'Rules:',
									'- Minimum bet: $0.1',
									'- ONLY games with "halloween" tag that are listed below can participate in promotion',
									'',
									'To participate in the event, you have to share your win to the support team. Please send our agent BET ID and a screenshot of your win. All prizes will be given after the event ends.',
								],
								text: 'OK',
								disableCancelButton: true,
							});
						}}
						label={trans({ label: 'Learn more' })}
					/>
				)}
			</div>
		</StyledSlotCategoryBanner>
	);
};
