import { IconProps } from '@icons/all-bets';
import { FC } from 'react';
import { Svg } from '@legacyApp/client/components/svg/Svg';
import { useId } from '../hooks/useId';

export const InHouse: FC<IconProps> = ({ fill, fillStop }) => {
	const { get } = useId({ id: 'inhouse' });
	const fill_ = fill || 'white';
	const fillStop_ = fillStop || fill;
	return Svg({
		content: (
			<>
				<path
					d="M7.8274 0.0175076C7.77954 0.0311503 7.69409 0.068669 7.63939 0.0993652C7.58128 0.130061 5.86531 1.82176 3.82118 3.86477C1.19253 6.48759 0.0884267 7.61311 0.0508257 7.70179C-0.00728494 7.84504 -0.0175398 8.12472 0.0303161 8.25091C0.0850085 8.38734 0.27985 8.58857 0.426836 8.65337C0.713971 8.78639 1.00452 8.71818 1.27457 8.45214L1.43523 8.29525V11.8628V15.4304L1.53094 15.6112C1.5993 15.7442 1.67109 15.8226 1.79415 15.8977L1.95822 16H7.99831H14.0384L14.2025 15.8977C14.3255 15.8226 14.3973 15.7442 14.4657 15.6112L14.5614 15.4304V11.8628V8.29525L14.7255 8.45214C14.9101 8.63632 15.081 8.71818 15.2792 8.71818C15.7851 8.71818 16.1406 8.17588 15.9458 7.70179C15.8774 7.5449 8.52473 0.188043 8.3333 0.085722C8.1829 0.0106868 7.95046 -0.0234207 7.8274 0.0175076ZM10.5518 4.2911L13.0915 6.82524V10.6861V14.5505H7.99831H2.90508V10.6896V6.82524L5.44144 4.29451C6.83952 2.89954 7.98806 1.76037 7.99831 1.76037C8.00857 1.76037 9.15711 2.89954 10.5518 4.2911Z"
					fill={get().url}
				/>
				<defs>
					<linearGradient
						id={get().id}
						x1="16"
						y1="16"
						x2="-1.43051e-06"
						y2="4.76837e-07"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor={fill_} />
						<stop offset="1" stopColor={fillStop_} />
					</linearGradient>
				</defs>
			</>
		),
		width: 16,
		height: 16,
	});
};
