import { createContext } from 'react';
import { SlotCategoryDTO } from '../store/slots.types';

export const SlotsCategoriesContext = createContext<{
	categories: SlotCategoryDTO[];
	setCategories: any;
}>({
	categories: [],
	setCategories: (value) => value,
});
