import { TabNavigationProps } from '@ui/tab-navigation';
import { FC, useCallback, useEffect, useState } from 'react';
import { TabButtonProps } from '@ui/tab-button';
import { useAppDispatch } from '@legacyApp/hooks/store/useAppDispatch';
import { updateUrlQuery } from '@legacyApp/client/store/app/app.actions';
import { QueryKeys, useRouterQueryKey } from '../../selectors/router.selectors';
import { useOnMount } from '../../hooks/useOnMount';
import { isFunction } from '../../methods/isFunction';

export interface UseTabNavigationProps {
	tabs: TabNavigationProps['tabs'];
	activeTabId?: TabNavigationProps['activeTabId'];
	defaultTabId?: TabNavigationProps['activeTabId'];
	queryKey?: QueryKeys;
	handleUrlQuery?: boolean;
	onChange?: (tab: TabButtonProps) => void;
}

export interface UseTabNavigationResult {
	activeTabId: TabNavigationProps['activeTabId'];
	onTabClick: TabNavigationProps['onClick'];
	changeTabById: (tabId: TabButtonProps['id']) => void;
	content: FC;
}

const getTab = (tabs: TabNavigationProps['tabs'], tabId?: string) => {
	if (!tabId) {
		return null;
	}
	return tabs.find((tab) => tab.id === tabId);
};

const getQueryKey = (queryKey: string) =>
	queryKey === undefined ? QueryKeys.TAB : queryKey;

export const useTabNavigation = ({
	tabs,
	activeTabId,
	defaultTabId,
	queryKey,
	handleUrlQuery,
	onChange,
}: UseTabNavigationProps): UseTabNavigationResult => {
	const [_queryKey, setQueryKey] = useState(getQueryKey(queryKey));
	const dispatch = useAppDispatch();
	const routerQueryTab = useRouterQueryKey(_queryKey);
	const [activeTab, setActiveTab] = useState<TabButtonProps>(null);

	const _setActiveTab = useCallback(
		(tab: TabButtonProps) => {
			if (tab?.id === activeTab?.id) {
				return;
			}
			setActiveTab(tab);
			if (handleUrlQuery && _queryKey) {
				dispatch(updateUrlQuery({ [_queryKey]: tab.id }));
			}
			if (isFunction(onChange)) {
				onChange(tab);
			}
		},
		[_queryKey, activeTab?.id, dispatch, handleUrlQuery, onChange],
	);

	const changeTabById = useCallback(
		(tabId: TabButtonProps['id']) => {
			if (tabId) {
				const tab = getTab(tabs, tabId);
				if (tab && tab.id !== activeTab?.id) {
					_setActiveTab(tab);
				}
			}
		},
		[_setActiveTab, activeTab?.id, tabs],
	);

	const onTabClick: TabNavigationProps['onClick'] = useCallback(
		(event, { id }) => {
			changeTabById(id);
		},
		[changeTabById],
	);

	useOnMount(() => {
		_setActiveTab(
			getTab(tabs, activeTabId || routerQueryTab || defaultTabId) || null,
		);
	});

	useEffect(() => {
		if (activeTabId && activeTabId !== activeTab?.id && !routerQueryTab) {
			const tab = getTab(tabs, activeTabId);
			if (tab && tab.id !== activeTab?.id) {
				_setActiveTab(tab);
			}
		}
	}, [_setActiveTab, activeTab?.id, activeTabId, tabs]);

	useEffect(() => {
		if (routerQueryTab && routerQueryTab !== activeTab?.id && !activeTabId) {
			const tab = getTab(tabs, routerQueryTab);
			if (tab && tab.id !== activeTab?.id) {
				_setActiveTab(tab);
			}
		}
	}, [_setActiveTab, activeTab?.id, routerQueryTab, tabs]);

	useEffect(() => {
		setQueryKey(getQueryKey(queryKey));
	}, [queryKey]);

	// console.log('useTabNavigation', { activeTabId, routerQueryTab, defaultTabId, queryKey });

	return {
		activeTabId: activeTab?.id || null,
		onTabClick,
		changeTabById,
		content: activeTab?.content || null,
	};
};
