import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { chatViewport } from '@legacyApp/client/modules/style/methods/chatViewport';
import { calcStyle } from '@legacyApp/client/modules/style/methods/calcStyle';
import { StyledLoader } from '@legacyApp/client/components/loader/Loader.styled';

export const StyledListWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-top: 1rem;
	padding-bottom: 40px;

	${StyledLoader} {
		background: ${rgba('#1c2533', 0.6)};
		pointer-events: none;
		position: fixed;
		z-index: 10;
	}

	${chatViewport(
		(viewportPadding) => css`
			@media (max-width: ${(props) =>
					calcStyle({
						a: props.theme.media_v2.max_mobile_medium,
						b: viewportPadding,
					})}) {
				padding-bottom: 20px;
			}
		`,
	)}
`;
