import { FC, useCallback } from 'react';
import styled from 'styled-components';
import { ScrollCardsWithTitle } from '@common/components/layout/ScrollCards/ScrollCardsWithTitle';
import { useGetDataArray } from '@legacyApp/hooks/fetch/useGetDataArray';
import { Slot, SlotCategoryDTO } from '../store/slots.types';
import { useFetchGames } from '../hooks/useFetchGames';
import { SLOTS_SCROLL_CARD_COUNT } from '../constants/Slots.constants';
import { useFetchNewGames } from '../hooks/useFetchNewGames';
import { SlotTile } from './SlotTile';

interface SlotsScrollCardsProps {
	name: string | JSX.Element;
	category: SlotCategoryDTO;
	slots: Slot[];
	count: number;
}

export const StyledSlotsScrollCardsTailWrapper = styled.div`
	margin-right: 16px;
	width: 153px;

	@media (max-width: ${(props) => props.theme.media.maxWidthTablet}) {
		margin-right: 12px;
		width: 134px;
	}

	@media (max-width: ${(props) => props.theme.media.maxWidthSmallTablet}) {
		margin-right: 10px;
		width: 97px;
	}
`;

export const SlotsScrollCards: FC<SlotsScrollCardsProps> = ({
	name,
	category,
	count,
	slots,
}) => {
	const { callback } = useFetchGames();
	const { callback: callbackNewGames } = useFetchNewGames();
	const gamesCallback = useCallback(
		() =>
			callback({
				count: SLOTS_SCROLL_CARD_COUNT,
				categoryId: category?.id,
			}),
		[callback, category?.id],
	);
	const newGamesCallback = useCallback(
		() =>
			callbackNewGames({
				count: SLOTS_SCROLL_CARD_COUNT,
			}),
		[callbackNewGames],
	);
	const {
		data: list,
		count: _count,
		loaded,
	} = useGetDataArray<Slot>(
		String(category.slug),
		category.id ? gamesCallback : newGamesCallback,
	);

	const slotsList = list?.length ? list : slots;
	const slotsCount = _count || count;

	if (loaded && !list?.length) {
		return;
	}

	return (
		<ScrollCardsWithTitle
			name={name}
			loaded={loaded}
			href={`/slots/${category.slug}`}
			count={slotsCount}
			list={slotsList?.map?.((slot, index) => {
				return {
					name: slot.name,
					component: (
						<StyledSlotsScrollCardsTailWrapper key={`${index}-${slot.slug}`}>
							<SlotTile {...slot} />
						</StyledSlotsScrollCardsTailWrapper>
					),
				};
			})}
		/>
	);
};
