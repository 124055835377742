import React, { FC } from 'react';
import styled from 'styled-components';

import { Slot } from '@modules/slots/store/slots.types';

import { LoaderListWrapper } from '@common/components/contentLoaders/LoaderListWrapper';
import { RectangleLoader } from '@common/components/contentLoaders/RectangleLoader';
import { StyledFlexContainer } from '@common/components/flex/FlexContainer';
import { StyledLoaderListItemWrapper } from '@modules/slots/components/LoaderListItemWrapper.styled';
import {
	SlotsGridLayout,
	SlotsGridLayoutProps,
} from '@modules/slots/components/SlotsGridLayout';
import { SlotTile } from '@modules/slots/components/SlotTile';

interface SlotsTileGridProps {
	items: Slot[];
	loaded: boolean;
	setGamesCount?: SlotsGridLayoutProps['setGamesCount'];
}

export const StyledSlotsGridLayout = styled.div`
	display: grid;
	grid-gap: 16px;
	grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
	margin-bottom: 16px;
	width: 100%;

	@media (max-width: ${(props) => props.theme.media.maxWidthTablet}) {
		grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
		grid-gap: 12px;
		margin-bottom: 12px;
	}

	@media (max-width: ${(props) => props.theme.media.maxWidthSmallTablet}) {
		grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
		grid-gap: 10px;
		margin-bottom: 10px;
	}
`;

export const TileSlotsGrid: FC<SlotsTileGridProps> = ({
	setGamesCount,
	items,
	loaded,
}) => {
	return (
		<LoaderListWrapper
			list={items}
			loaded={loaded}
			loaderWrapper={StyledFlexContainer}
			preloadElement={(el, index) => (
				<StyledLoaderListItemWrapper key={index}>
					<RectangleLoader id={index} />
				</StyledLoaderListItemWrapper>
			)}
			preloadLength={10}
			listElement={
				<SlotsGridLayout setGamesCount={setGamesCount}>
					{items.map((game) => {
						return (
							<SlotTile key={`${game.game_id}_${game.operator_id}`} {...game} />
						);
					})}
				</SlotsGridLayout>
			}
		/>
	);
};
