import React, { FC } from 'react';
import styled from 'styled-components';
import { transSlots } from '@legacyApp/client/modules/translation/translate';

export const StyledNoGames = styled.div.attrs({
	className: 'text-style-md-regular',
})`
	display: block;
	padding: 0 0 1rem 1rem;
`;

export const NoGames: FC = () => {
	return (
		<StyledNoGames>
			{transSlots({ label: 'There are no games in this category' })}.
		</StyledNoGames>
	);
};
