import { useCallback, useContext } from 'react';
import { useFetchApiPromise } from '@legacyApp/hooks/fetch/useFetchApi';
import { UseGetDataArrayCallback } from '@legacyApp/hooks/fetch/useGetDataArray';
import { SlotCategoryDTO } from '../store/slots.types';
import { generateGetCategories } from '../services/Slots.service';
import { SlotsCategoriesContext } from '../context/SlotsCategoriesContext';

export const useFetchCategories = () => {
	const fetchApi = useFetchApiPromise();

	const contextSlotsCategories = useContext(SlotsCategoriesContext);

	const callback: UseGetDataArrayCallback<SlotCategoryDTO> =
		useCallback(async () => {
			const { categories: _categories } = await fetchApi({
				url: generateGetCategories(),
				disableErrorHandler: true,
				fromState: true,
			}).catch(() => ({
				categories: contextSlotsCategories?.categories,
			}));

			if (contextSlotsCategories?.setCategories) {
				contextSlotsCategories.setCategories(
					_categories || contextSlotsCategories?.categories,
				);
			}

			return {
				data: _categories || contextSlotsCategories?.categories || [],
			};
		}, [contextSlotsCategories, fetchApi]);

	return {
		callback,
		options: {
			data: contextSlotsCategories?.categories,
		},
	};
};
