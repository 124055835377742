import React, { FC } from 'react';
import styled from 'styled-components';

import { fontSize } from '@legacyApp/client/modules/style/mixins/fontSize';
import { trans } from '@legacyApp/client/modules/translation/translate';
import { generateSlotProviderUrl } from '@modules/slots/services/Slots.service';
import { ProviderDTO } from '@modules/slots/store/slots.types';

import { LinkTo } from '@common/components/default/LinkTo/LinkTo';
import { ProviderLogo } from '@modules/slots/components/ProviderLogo';

interface ProviderTileProps {
	slug: ProviderDTO['slug'];
	isNew: ProviderDTO['new'];
	name: ProviderDTO['name'];
}

export const StyledProviderTileContainer = styled.div`
	align-items: center;
	background: var(--color-dark-500);
	border-radius: 12px;
	cursor: pointer;
	display: flex;
	height: 80px;
	justify-content: center;
	padding: 8px 24px;
	width: 216px;

	@media (max-width: ${(props) => props.theme.media.maxWidthTablet}) {
		height: 70px;
		width: 191px;
	}

	@media (max-width: ${(props) => props.theme.media.maxWidthSmallTablet}) {
		height: 60px;
		width: 141px;
	}

	> img {
		width: 100%;
	}
`;

const StyledProviderNewBadge = styled.span`
	background: ${(props) => props.theme.colors.gold};
	border-radius: 12px;
	${fontSize({ size: '12px', sizeDesktop: '12px', sizeBigScreen: '12px' })};
	display: inline-block;
	padding: 0.1rem 0.4rem;
	position: absolute;
	right: 0;
	text-transform: uppercase;
	top: auto;
`;

const StyledATag = styled.a`
	display: block;
	height: 100%;
	position: relative;
	width: 100%;

	&:hover {
		opacity: 0.65;
	}
`;

export const ProviderTile: FC<ProviderTileProps> = ({ slug, isNew, name }) => {
	return (
		<LinkTo href={generateSlotProviderUrl(slug)} passHref>
			<StyledATag>
				{isNew ? (
					<StyledProviderNewBadge>
						{trans({ label: 'new' })}
					</StyledProviderNewBadge>
				) : null}

				<StyledProviderTileContainer>
					<ProviderLogo slug={slug} name={name} />
				</StyledProviderTileContainer>
			</StyledATag>
		</LinkTo>
	);
};
