import React, { FC, PropsWithChildren } from 'react';
import { Button } from '@ui/button';
import { DropdownArrow } from '@ui/dropdown/DropdownArrow';
import {
	StyledFilterContainer,
	StyledFilterSelect,
} from '@modules/slots/components/SlotsFilters';

import { Size } from '@uiTypes/Size';
import { ButtonStyleType } from '@uiTypes/ButtonStyleType';
import { dropdownDelete } from '@legacyApp/client/store/dropdown/dropdown.actions';
import { useAppDispatch } from '@legacyApp/hooks/store/useAppDispatch';
import DropdownContainer from '../../../LegacyApp/client/components/dropdown/dropdown.container';
import { isFunction } from '../../methods/isFunction';

export interface FilterDropdownElement {
	id: string;
	element?: JSX.Element;
	label?: string;
}

export interface FilterDropdownProps {
	onClick?: (item: FilterDropdownElement) => void;
	id: string;
	list: ReadonlyArray<FilterDropdownElement>;
}

export const FilterDropdown: FC<PropsWithChildren<FilterDropdownProps>> = ({
	onClick,
	id,
	children,
	list,
}) => {
	const dispatch = useAppDispatch();
	return (
		<StyledFilterContainer>
			<StyledFilterSelect>
				<DropdownContainer
					id={id}
					active={
						<Button
							label={children}
							size={Size.MEDIUM}
							styleType={ButtonStyleType.SECONDARY}
							name="dropdown-active"
						>
							<DropdownArrow />
						</Button>
					}
					localTheme={{ default: true }}
					list={list.map((item) => ({
						...item,
						onClick: () => {
							if (isFunction(onClick)) {
								onClick(item);
							}
							dispatch(dropdownDelete(id));
						},
					}))}
				/>
			</StyledFilterSelect>
		</StyledFilterContainer>
	);
};
