import styled from 'styled-components';

export const StyledSlotsSectionsContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 8px;

	@media (max-width: ${(props) => props.theme.media.maxWidthTablet}) {
		padding-bottom: 50px;
	}

	@media (max-width: ${(props) => props.theme.media_v2.max_mobile_medium}) {
		margin-top: 4px;
		padding-bottom: 25px;
	}
`;
