import { createContext } from 'react';
import { TabButtonProps } from '@ui/tab-button';
import { UseTabNavigationResult } from './useTabNavigation';

export const TabNavigationContext = createContext<{
	activeId: TabButtonProps['id'];
	changeTabById: UseTabNavigationResult['changeTabById'];
}>({
	activeId: null,
	changeTabById: null,
});
