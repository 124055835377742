import { useCallback } from 'react';
import { useFetchApiPromise } from '@legacyApp/hooks/fetch/useFetchApi';
import { UseGetDataArrayCallback } from '@legacyApp/hooks/fetch/useGetDataArray';
import { generateGetNewGamesUrl } from '../services/Slots.service';
import { normalizeSlots } from '../services/Slots.mapper';
import { Slot } from '../store/slots.types';

export const useFetchNewGames = () => {
	const fetchApi = useFetchApiPromise();

	const callback: UseGetDataArrayCallback<
		Slot,
		{
			count: number;
		}
	> = useCallback(
		async ({ count } = { count: undefined }) => {
			const result = await fetchApi({
				url: generateGetNewGamesUrl(count),
				disableErrorHandler: true,
			}).catch(() => ({
				list: [],
			}));

			return {
				data: normalizeSlots(result?.list || []),
				count: result?.list?.length || result?.length || 0,
			};
		},
		[fetchApi],
	);

	return {
		callback,
	};
};
