import React, { FC } from 'react';
import styled from 'styled-components';
import { Button } from '@ui/button';
import {
	trans,
	transSlots,
} from '@legacyApp/client/modules/translation/translate';
import { StyledProgressBar } from '@common/components/progressBar/ProgressBar.styled';
import { Size } from '@uiTypes/Size';
import { ButtonStyleType } from '@uiTypes/ButtonStyleType';

interface ShowMoreProps {
	displayingGames: number;
	allGames: number;
	showMore: () => void;
}

const StyledShowMoreContainer = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	margin: 1rem 0;
	${StyledProgressBar} {
		margin-bottom: 0.75rem;
	}
`;

const StyledDisplayingGamesInfo = styled.div`
	margin-bottom: 1.5rem;
`;

export const ShowMore: FC<ShowMoreProps> = ({
	displayingGames,
	allGames,
	showMore,
}) => {
	const progress = (displayingGames / allGames) * 100;
	return (
		<StyledShowMoreContainer>
			<StyledProgressBar progress={progress} />
			<StyledDisplayingGamesInfo className="text-style-sm-regular">
				{transSlots({
					label: 'Displaying {{numberOfGames}} of {{numberOfAllGames}} games',
					options: {
						numberOfGames: displayingGames,
						numberOfAllGames: allGames,
					},
				})}
			</StyledDisplayingGamesInfo>
			{displayingGames !== allGames && (
				<Button
					label={trans({ label: 'Show more' })}
					size={Size.LARGE}
					styleType={ButtonStyleType.SECONDARY}
					name={'show_more_slots'}
					onClick={showMore}
				/>
			)}
		</StyledShowMoreContainer>
	);
};
