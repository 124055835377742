import React from 'react';
import styled from 'styled-components';
import { categoriesIcons } from '@icons/slots/categories';
import { NewGamesIcon } from '@icons/slots/categories/NewGamesIcon';
import { generateUrl, ROUTE } from 'src/common/routes';
import { LinkTo } from 'src/common/components/default/LinkTo/LinkTo';
import { HomeIcon, SearchIcon } from 'src/common/icons/slots';
import { openModal } from 'src/modules/modals/store/modal.actions';
import { useAppDispatch } from 'src/LegacyApp/hooks/store/useAppDispatch';
import { useUserIsLogged } from 'src/common/selectors/user.selectors';
import { MODAL_ID } from 'src/common/constants/modal/ids.modal.constants';
import {
	trans,
	transSlots,
} from 'src/LegacyApp/client/modules/translation/translate';
import { theme } from 'src/LegacyApp/client/modules/style/theme';
import FavoriteIcon from 'src/common/icons/slots/favorite';
import { useFetchCategories } from 'src/modules/slots/hooks/useFetchCategories';
import { EmptyIcon } from 'src/common/components/icons/Icon';
import { SlotCategoryDTO } from 'src/modules/slots/store/slots.types';
import { useGetDataArray } from 'src/LegacyApp/hooks/fetch/useGetDataArray';

export interface MenuItem {
	name: string;
	icon: ({
		height,
		width,
		color,
	}: {
		height?: number;
		width?: number;
		color?: string;
	}) => React.ReactNode;
	url: string;
	onClick?: () => void;
}

const StyledMenuContainer = styled.div`
	display: flex;
	margin: 0 auto;
`;

const StyledMenuWrapper = styled.div`
	align-items: center;
	display: flex;
	overflow-x: auto;
	padding: 0.5rem 0 0;
`;

const StyledMenuItem = styled.div`
	cursor: pointer;
	display: block;
	font-family: ${(props) => props.theme.text.fonts.Regular};
	height: 60px;
	padding: 0 0.6rem;
	text-align: center;
	white-space: nowrap;

	@media (max-width: ${(props) => props.theme.media.maxWidthSmallTablet}) {
		height: 50px;
		padding: 0 0.4rem;
	}

	&:hover {
		opacity: 0.65;
	}
`;

const StyledIconWrapper = styled.div`
	height: 35px;

	svg {
		height: 30px;
	}

	@media (max-width: ${(props) => props.theme.media.maxWidthSmallTablet}) {
		height: 30px;

		svg {
			height: 25px;
		}
	}
`;

const StyledIconName = styled.div`
	@media (max-width: ${(props) => props.theme.media.maxWidthSmallTablet}) {
		font-size: 12px;
	}
`;

export const filterMenuCategories = (categories: SlotCategoryDTO[]) => {
	return categories.filter((category) => !!category.show_in_menu);
};

export const useMenuCategories = () => {
	const { callback, options } = useFetchCategories();
	const { data: categories } = useGetDataArray<SlotCategoryDTO>(
		'menu-categories',
		callback,
		options,
	);
	return filterMenuCategories(categories);
};

export const SlotsMenu: React.FC = () => {
	const dispatch = useAppDispatch();
	const isLogged = useUserIsLogged();

	const menuCategories = useMenuCategories();

	const menuItems: MenuItem[] = [
		{
			name: trans({ label: 'Home' }),
			icon: (props) => <HomeIcon {...props} />,
			url: generateUrl(ROUTE.slotsHomePage),
		},
		isLogged && {
			name: transSlots({ label: 'My Favorite' }),
			icon: (props) => <FavoriteIcon {...props} />,
			url: generateUrl(ROUTE.slotFavorites),
		},
		...menuCategories.map((category) => {
			return {
				name: transSlots({ label: category.name }),
				icon: (props) => {
					const Icon = categoriesIcons[category.slug] || EmptyIcon;
					return <Icon {...props} />;
				},
				url: generateUrl(ROUTE.slotCategoryPage, {
					category: category.slug,
				}),
			};
		}),
		{
			name: transSlots({ label: 'New Games' }),
			icon: (props) => <NewGamesIcon {...props} />,
			url: generateUrl(ROUTE.slotNewGames),
		},
		{
			name: trans({ label: 'Search' }),
			icon: (props) => <SearchIcon {...props} />,
			url: null,
			onClick: () => dispatch(openModal(MODAL_ID.SLOT_SEARCH, 'SlotsMenu')),
		},
	];

	return (
		<StyledMenuWrapper>
			<StyledMenuContainer>
				{menuItems.map((item, index) => {
					if (!item) {
						return null;
					}
					const props = {
						height: 55,
						width: 55,
						color: theme.colors.active,
					};
					const component = (
						<StyledMenuItem key={index} onClick={item.onClick}>
							<StyledIconWrapper>{item.icon(props)}</StyledIconWrapper>
							<StyledIconName>{item.name}</StyledIconName>
						</StyledMenuItem>
					);

					if (item.onClick) {
						return component;
					}
					return (
						<LinkTo forceATag={true} key={`link-${index}`} href={item.url}>
							{component}
						</LinkTo>
					);
				})}
			</StyledMenuContainer>
		</StyledMenuWrapper>
	);
};
