import { IconProps } from '@icons/all-bets';
import { Svg } from '@legacyApp/client/components/svg/Svg';

export const FavoriteIconV2 = ({ fill }: IconProps) => {
	const fill_ = fill || 'white';
	return (
		<Svg
			content={
				<path
					d="M264.434,745.453l1.771,3.589a.842.842,0,0,0,.634.46l3.96.576a.842.842,0,0,1,.467,1.437l-2.865,2.793a.84.84,0,0,0-.243.745l.677,3.944a.843.843,0,0,1-1.223.888l-3.541-1.862a.842.842,0,0,0-.784,0l-3.542,1.862a.842.842,0,0,1-1.222-.888l.676-3.944a.842.842,0,0,0-.242-.745l-2.866-2.793a.842.842,0,0,1,.467-1.437l3.96-.576a.84.84,0,0,0,.634-.46l1.771-3.589A.843.843,0,0,1,264.434,745.453Z"
					transform="translate(-255.072 -744.234)"
					fill={'transparent'}
					stroke={fill_}
					strokeMiterlimit="10"
					strokeWidth="1.5"
				/>
			}
			width={17.212}
			height={16.519}
		/>
	);
};
