import styled, { css } from 'styled-components';

const ARROW_COLOR = 'white';

export const controlArrow = css`
	&:before {
		border-bottom: 2px solid ${ARROW_COLOR} !important;
		border-left: unset !important;
		border-right: 2px solid ${ARROW_COLOR} !important;
		border-top: unset !important;
		content: '';
		display: block;
		height: 6px;
		width: 6px;
	}

	&.control-next:before {
		transform: rotate(315deg);
	}

	&.control-prev:before {
		transform: rotate(135deg);
	}
`;

export const StyledControlArrow = styled.div.attrs((props) => ({
	...props,
	className: `control-${props.$type}`,
}))`
	${controlArrow};

	&.control-next {
		margin-left: 10px;
		margin-right: 9px;
	}

	&.control-prev {
		margin-left: 11px;
		margin-right: 9px;
	}

	@media (max-width: ${(props) => props.theme.media.maxWidthTablet}) {
		padding: 10px 0;

		&.control-next {
			padding-right: 15px;
		}
	}
`;
